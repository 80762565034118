<template>
  <section ref="me" :class="panelClass">
    <div class="box" :class="panelBorderClass" :style="cssvars">
      <DashboardPanelHeader
        ref="panelHeader"
        :panel="panel"
        :mode="mode"
        :isEditing="isEditing"
        :equipment="equipment"
        customBoxTitleClasses="box-title"
        @title:click="$emit('title:click')"
        @resized="onHeaderResized"
        @noTitle="noTitle = $event"
      >
        <template #toolbar><slot name="toolbar"></slot></template>
        <template #panel-editor-toolbar v-if="isEditing">
          <SynopticEditorToolbar v-if="isEditing" />
        </template>
      </DashboardPanelHeader>
      <div class="box-body box-body-synotic" ref="synoptic">
        <SynopticDisplay
          ref="display"
          :equipment="equipment"
          :display="display"
          :mode="mode"
          :panel="panel"
          :isEditing="isEditing"
          :isPanelLocked="isPanelLocked"
          :isLinked="isLinked"
          :headerSize="headerSize"
          :key="restoreCounter"
        />
      </div>
      <Spin v-if="!ready" />
    </div>
  </section>
</template>

<script>
import Spin from "@/components/spin";
import SynopticDisplay from "@/components/synoptic/synoptic-display.vue";
import SynopticEditorToolbar from "@/components/synoptic/synoptic-editor-toolbar.vue";
import DashboardPanelHeader from "@/components/dashboard-panel-header.vue";
export default {
  name: "SynopticPanel",
  components: {
    SynopticDisplay,
    Spin,
    SynopticEditorToolbar,
    DashboardPanelHeader
  },
  data() {
    return {
      area: 0,
      refreshTimer: null,
      ready: true,
      referenceIds: null,
      restoreCounter: 0,
      noTitle: false,
      headerSize: {
        width: 0,
        height: 0
      }
    };
  },
  props: {
    equipment: {type: Object, required: false, default: () => ({})},
    panel: {type: Object, required: true},
    display: {type: Object, required: false},
    panelName: {type: String, required: true},
    mode: {type: String, required: true, default: "viewer"},
    screenId: {type: [String, Number], required: false, default: () => 0},
    title: {type: String, required: true},
    isEditing: {type: Boolean, required: false, default: () => false}
  },
  computed: {
    panelBorderClass() {
      if (window.location != window.parent.location && !this.panel.iframeTitle)
        return "embedded";
      return [
        this.isEditing ? "box-warning" : "box-primary",
        this.fitToPage || this.panelClass !== "content-top"
          ? "box-fit-to-page"
          : "",
        this.noTitle ? "no-title" : ""
      ];
    },
    panelOptions: function() {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    dashboardDraftPanel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    panelStyle() {
      return this?.panel?.style || {};
    },
    panelClass() {
      const vlr = (this?.panelOptions?.canvas || {})["vertical-align"] ?? "top";
      return `content-${vlr}`;
    },
    panelToolBarConfig() {
      return {toolbar: this.panel.toolbar, type: this.panel.toolbarType};
    },
    cssvars() {
      return {
        "--background-color":
          (this.panelStyle || {})["background-color"] ?? "#FFFFFF"
      };
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"];
    },
    isPanelLocked() {
      return this.mode == "editor" && this.panel.locked;
    },
    fitToPage() {
      return (
        this.mode == "viewer" && (this?.panel?.options?.fitToPage ?? false)
      );
    },
    linkedScreen() {
      return (this.display.linkedPanels || {})[this.panel.name] ?? null;
    },
    isLinked() {
      return this.linkedScreen ? true : false;
    },
    isSyncEnabled() {
      return (this.linkedScreen && this.linkedScreen.syncEnabled) || false;
    },
    panelTitle() {
      return this.panel?.title || "";
    },
    toolbarStyle() {
      return this.panel?.toolbarStyle || {};
    },
    toolbarStyleFontSize() {
      return this.toolbarStyle['font-size'] || "";
    },
    boxHeaderTitlePadding() {
      if (this.panelTitle && !this.toolbarStyleFontSize) return "0 5px";
      return "10px";
    }
  },
  watch: {
    display: {
      handler(n, o) {
        if (this.ready) {
          if (n && o && n.id && o.id && n.id != o.id) {
            this.ready = false;
            this.$nextTick(() => {
              this.ready = true;
            });
          }
        }
      },
      deep: true
    },
    isEditing: {
      handler(n, o) {
        if (!this.mode == "editor") return;
        if (n) {
          if (!o) {
            this.$store.dispatch("synoptic/setupPanel", {
              id: this.screenId,
              panel: this.panel
            });
            // this.$emit("initCustomProperties", {
            //   panelName: this.panelName,
            //   propertyEditor: SynopticPropertyEditor
            // });
          }
          // this.$root.$emit("panelFocus");
          this.$root.$emit("editor.keyboard:focus");
        } else if (o) {
          let panelName = this.$store.getters["synoptic/panel"]?.name ?? "";
          if (panelName && panelName == this.panelName) {
            this.$store.dispatch("synoptic/resetState");
          }
        }
      },
      immediate: true
    },
    panelStyle(n) {
      // Important: This function sync properties that are not managed by synoptic.
      this.$nextTick(() => {
        if (!this.isEditing) return;
        this.$store.dispatch("synoptic/setPanelProperties", {
          name: this.panelName,
          style: {...n}
        });
      });
    },
    panelToolBarConfig(n) {
      // Important: This function sync properties that are not managed by synoptic.
      this.$nextTick(() => {
        if (!this.isEditing) return;
        this.$store.dispatch("synoptic/setPanelProperties", {
          name: this.panelName,
          toolbar: [...n.toolbar],
          toolbarType: n.type
        });
      });
    }
  },
  methods: {
    onUpdateParams(params) {
      (this?.panelOptions?.controls || [])
        .filter(
          (ctrl) =>
            ctrl.enabled &&
            ctrl.synopticComponent &&
            ctrl.name in (params || {})
        )
        .forEach((ctrl) => {
          var dataId = params[ctrl.name]?.data_id || params[ctrl.name] || "";
          if (dataId != ctrl.data_id) {
            console.log(dataId);
            this.$set(ctrl, "data_id", dataId);
            if (
              !(this.$store.getters["dashboard/dataList"] || []).some(
                ({id}) => id == dataId
              )
            ) {
              this.$store.dispatch("dashboard/fetchResources", {
                resource: "data",
                list: [dataId],
                forceUpdate: true
              });
            }
          }
        });
    },
    setupEmbeddedPage() {
      // this method should only be called while in embedded mode (iframe).
      if (window.location == window.parent.location) return;
      window.onmessage = (e) => {
        if (e.data && typeof e.data == "object" && "hi_msg" in (e.data || {})) {
          let params = e?.data?.hi_msg?.action?.options?.params || {};
          this.onUpdateParams(params);
        }
      };
      window.top.postMessage({hi_msg: "NACK", from: window.name}, "*");
    },
    onHeaderResized(r) {
      this.headerSize.width = r.width;
      this.headerSize.height = r.height;
    }
  },
  created() {
    if (this.mode == "editor") return;
    if (window.location !== window.parent.location) {
      this.setupEmbeddedPage();
    }
  },
  mounted() {
    let self = this;
    document.addEventListener("visibilitychange", () => {
      if (
        this.ready &&
        !document.hidden &&
        this.$refs.display &&
        this.$refs.display.hasDirtyControls &&
        !this.$refs.display.hasDirtyControls()
      ) {
        self.restoreCounter += 1;
      }
    });
  }
};
</script>

<style scoped>
.content-top {
  display: flex;
  align-items: flex-start;
}
.content-bottom {
  display: flex;
  align-items: flex-end;
}
.content-middle {
  display: flex;
  align-items: center;
}
.content-strech {
  display: flex;
  align-items: stretch;
}

.box {
  margin: 0;
  padding: 0;
  box-shadow: none;
  background-color: var(--background-color);
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.embedded {
  border-top-width: 0;
  margin-top: -20px;
}

.nav-tabs-custom {
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.box-body-synotic {
  padding: 0;
}

.box-header {
  padding: v-bind('boxHeaderTitlePadding') !important;
  display: flex;
}

.box-header > span {
  padding: 0 10px 0 2px;
  font-size: 20px;
}

.fa-redo {
  transform: rotateY(180deg);
}

.clicable-title:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #31708f;
}

.box-fit-to-page {
  border: none;
}

.box-fit-to-page.no-title > div.box-header {
  display: none;
}
</style>

<style lang="scss" scoped>
@media print {
  .no-print {
    display: none;
  }
}
</style>
